@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

.App {
  background-color: #C5CBD3;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.App-flex-st {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.App-flex-mt {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.App-flex-left {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}

.App-signin-st {
  opacity: 85%;
  height: 100%;
  width: 43%;
  display: flex;
  flex-direction: column;
}

.App-signin-mt {
  position: absolute;
  opacity: 85%;
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.App-heading {
  background: linear-gradient(teal, 70%, transparent);
  background-color: #222;
  height: 27%;
  color: darkolivegreen;
  font-size: 3vh;
  padding-top: 1em;
  display: inline-flex;
  align-items: baseline;
}

.App-footer {
  background-color: royalblue;
  color: #f8f8f8;
  font-size: 1vh;
  /* box-shadow: 0px 4px 4vh 4px rgba(34,34,34,0.9); */
}

.App-misenz-a {
  color: #2e361f;
}

.App-misenz-b {
  color: #daa51b;
}

/* .App-logo {
  max-height: 30vh;
  max-width: 30vh;
} */

.App-instructions {
  background-color: #f8f8f8;
  color: #222;
  font-size: 3vh;
  line-height: 1.5;
  padding: 0 1em;
}

.App-instructions code {
  background-color: #222;
  color: #00d8ff;
  padding: .2em .3em;
  border-radius: .2em;
}

.bp4-button {
  outline: none;
}

.bp4-tab {
  outline: none;
}
